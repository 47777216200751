<template>
  <div v-if="tournament" class="filters" :class="{ 'filters--mobile': isMobile }">
    <BField grouped name="filters">
      <BField horizontal label="Liga:" class="filters__field" :expanded="false" name="league">
        <BSelect :value="tournament" :expanded="isMobile" :disabled="isGameLoading" @input="onTournamentInput">
          <option v-for="(tournament, index) of tournaments" :key="index" :value="tournament">{{
            tournament.name
          }}</option>
        </BSelect>
      </BField>
      <BField horizontal label="Jornada:" :class="{ filters__field: !isMobile }">
        <BSelect v-if="tournament" :value="round" :disabled="isGameLoading" @input="onRoundInput">
          <option v-for="(round, index) of tournament.gamesByRound" :key="index + 100" :value="round">{{
            round.abbreviation2
          }}</option>
        </BSelect>
      </BField>
      <BField v-if="!isMobile" horizontal label="Partido:" class="filters__field">
        <BSelect :value="game" @input="onGameInput" :disabled="isGameLoading" :key="bSelectKey">
          <option v-for="(game, index) of round.games" :key="index + 200" :value="game">{{ getGameName(game) }}</option>
        </BSelect>
      </BField>
    </BField>
  </div>
</template>

<script>
import { BSelect } from 'buefy/dist/esm/select';
import { mapMutations, mapGetters } from 'vuex';
import { RightBetSelecLeague, RightBetSelecMatchday, RightBetSelecGame } from '@/utils/analytics';
export default {
  name: 'Filters',
  components: {
    BField: async () => {
      const { BField } = await import('buefy/dist/esm/field');
      return BField;
    },
    BSelect,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    tournaments: {
      type: Array,
      default: () => [],
    },
    isPremiumUser: {
      type: Boolean,
      default: true,
    },
    gameFromCarousel: {
      type: Object,
      default: () => ({}),
    },
    isGameLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tournament: {
        gamesByRound: [],
      },
      round: {
        games: [],
      },
      game: null,
      bSelectKey: 1000,
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  watch: {
    tournaments: {
      deep: true,
      handler(newValue) {
        this.tournament = newValue[0];
      },
    },
    tournament(newValue) {
      this.$emit('tournament-change', newValue);
    },
    gameFromCarousel(newValue) {
      this.round = this.tournament.gamesByRound.find(round => round.abbreviation === newValue.matchday_abbreviation);
      this.game = this.round.games.find(game => game.game_id === newValue.game_id);
    },
  },
  methods: {
    ...mapMutations(['SET_PREMIUM_MESSAGE_V2']),
    getGameName(game) {
      return `${game.homeTeam.colloquial_name} vs ${game.awayTeam.colloquial_name}`;
    },
    onTournamentInput(tournament) {
      this.tournament = tournament || this.getEmptyTournament();
      RightBetSelecLeague({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        tournament_id: this.tournament.id,
      });
    },
    onRoundInput(round) {
      this.round = round;
      this.game = this.isPremiumUser ? round.games[0] : round.games[round.games.length - 1];
      RightBetSelecMatchday({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        value: this.round.abbreviation2,
      });
      this.$emit('game-change', this.game);
    },
    onGameInput(game) {
      if (this.isPremiumUser) {
        this.game = game;
        this.$emit('game-change', this.game);
      } else {
        this.bSelectKey += 1;
        this.SET_PREMIUM_MESSAGE_V2(true);
      }
      RightBetSelecGame({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        game_id: this.game.game_id,
      });
    },
    getEmptyTournament() {
      return {
        gamesByRound: [],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  width: 100%;

  &__field {
    margin-right: 2rem !important;
  }
}
</style>

<style lang="scss">
.filters {
  & .select select {
    font-family: Roboto-Medium, sans-serif;
  }

  & .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #b5db77;
    right: 1.125em;
    z-index: 4;
  }

  &.filters--mobile {
    padding: 0 0.5rem;

    & div.control {
      width: 100%;
    }

    & .select {
      width: inherit;

      & select {
        width: inherit;
      }
    }

    & div.field-label > label.label {
      font-family: Roboto-Regular, sans-serif;
      font-size: 0.8rem;
      padding-left: 0.5rem;
      color: #757c82;
      font-weight: 100;
    }

    & div.field.is-grouped {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr min-content;

      & div.control .select select {
        font-family: Roboto-Medium, sans-serif;
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #373a3e;
      }

      & > div.field:last-child {
        width: 100px;
      }
    }
  }

  &__field {
    & > div.field-label > label.label {
      color: black;
    }
  }
}
</style>
